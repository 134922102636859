.rdtPicker {
  color: black;
  background-color: white !important;
}
.avatar-xs {
  height: 4rem;
  width: 4rem;
}
.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
}
.avatar-group .avatar-group-item {
  margin-left: -12px;
  border: 2px solid;

  transition: all 0.2s;
}
.avatar-group .avatar-group-item:hover {
  position: relative;
  transform: translateY(-2px);
}
