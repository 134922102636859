#canvas-container {
    height: 40vh;
    width: auto;
    position: relative;
 }

 /* @media (min-width: 768px) {
     #canvas-container {
         height: 50vh;
         width: auto;
      }
 } */